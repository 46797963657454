import React, { memo } from 'react';
import { itemSettingStyle } from '../../../../../utils/helpers';
import CommonDeleteBtn from '../../commonDeleteBtn';

const PersonalView = ({
  selectedHistories,
  setSelectedHistories,
  prescriptionItems,
  isHistoryPage,
  removeItemsData,
}) => {
  const deleteData = (fieldName, delId) => {
    const updatedInvestigations = selectedHistories?.investigations?.filter(
      (investigation) => investigation.name !== delId,
    );
    setSelectedHistories({
      ...selectedHistories,
      investigations: updatedInvestigations,
    });
  };

  return (
    <>
      <div className="his-sub-title">P/H</div>
      <ul>
        <li className="text-left">
          {selectedHistories.personal.smoker && (
            <>
              <span
                style={itemSettingStyle(
                  prescriptionItems?.items?.history?.itemStyle || {},
                )}
              >
                Smoker:{' '}
              </span>
              <span
                style={itemSettingStyle(
                  prescriptionItems?.items?.history?.subItemStyle || {},
                )}
              >
                {' '}
                Yes{' '}
              </span>
            </>
          )}
          {selectedHistories.personal.alcoholic && (
            <>
              <span
                style={itemSettingStyle(
                  prescriptionItems?.items?.history?.itemStyle || {},
                )}
              >
                Alcoholic:{' '}
              </span>
              <span
                style={itemSettingStyle(
                  prescriptionItems?.items?.history?.subItemStyle || {},
                )}
              >
                {' '}
                Yes
              </span>
            </>
          )}
          {selectedHistories.personal.tobacco && (
            <>
              <span
                style={itemSettingStyle(
                  prescriptionItems?.items?.history?.itemStyle || {},
                )}
              >
                {' '}
                Tobacco:{' '}
              </span>{' '}
              <span
                style={itemSettingStyle(
                  prescriptionItems?.items?.history?.subItemStyle || {},
                )}
              >
                {' '}
                Yes
              </span>
            </>
          )}
          {!isHistoryPage && (
            <CommonDeleteBtn
              action={removeItemsData}
              itemName={'personal'}
              delId={'personal'}
            />
          )}
        </li>
        {selectedHistories.personal.notes && (
          <li className="text-left">
            <span
              style={itemSettingStyle(
                prescriptionItems?.items?.history?.itemStyle || {},
              )}
            >
              Note:
            </span>{' '}
            <span
              style={itemSettingStyle(
                prescriptionItems?.items?.history?.subItemStyle || {},
              )}
            >
              {selectedHistories.personal?.notes}
            </span>
          </li>
        )}
      </ul>
    </>
  );
};

export default memo(PersonalView);
