import React, { memo } from 'react';
import {
  isExistHistory,
  styledComponent,
  isExistAnyHistory,
} from '../../../../utils/helpers';
import GynecologyView from './history/gynecology';
import SurgicalView from './history/surgicalView';
import OthersView from './history/othersView';
import DrugHistoryView from './history/drugHistoryView';
import InvestigationView from './history/investigationView';
import PersonalView from './history/personalView';
import FamilyView from './history/familyView';
import MedicalView from './history/medicalView';

const HistoryView = ({
  element,
  setShowHistory,
  selectedHistories,
  setSelectedHistories,
  prescriptionItems,
  isHistoryPage = false,
}) => {
  const removeItemsData = (fieldName, delId) => {
    switch (fieldName) {
      case 'medicalNote':
        selectedHistories.medical.notes = '';
        break;
      case 'D_H':
      case 'D_A':
        selectedHistories.drugs[fieldName] = [];
        break;
      case 'personal':
        selectedHistories.personal = {
          smoker: false,
          alcoholic: false,
          tobacco: false,
          notes: '',
        };
        break;
      case 'family':
        selectedHistories.family = '';
        break;
      case 'medical':
        selectedHistories.medical = selectedHistories?.medical?.filter(
          (data) => data.name !== delId,
        );
        break;
    }
    setSelectedHistories({ ...selectedHistories });
  };

  const Title = styledComponent(
    prescriptionItems?.items?.['history']?.itemStyle || {},
  );
  const Value = styledComponent(
    prescriptionItems?.items?.['history']?.subItemStyle || {},
  );

  return (
    <>
      <div
        className={`row item-row ${
          !isExistAnyHistory(selectedHistories)
            ? 'no-data-item-history d-block'
            : 'data-item-history d-block'
        }`}
        key={`history`}
      >
        <div
          className="prescription-item history-item"
          {...(!isHistoryPage
            ? {
                onClick: (e) => {
                  e.stopPropagation();
                  setShowHistory(true);
                },
              }
            : {})}
        >
          {element?.alterName?.length ? (
            <span>{element?.alterName} +</span>
          ) : (
            <span>History +</span>
          )}
        </div>

        {selectedHistories && (
          <div className="history-view">
            {isExistHistory(selectedHistories, 'medical') && (
              <MedicalView
                selectedHistories={selectedHistories}
                setSelectedHistories={setSelectedHistories}
                isHistoryPage={isHistoryPage}
                isDelBtn="true"
                removeItemsData={removeItemsData}
              />
            )}
            {isExistHistory(selectedHistories, 'drugs') && (
              <DrugHistoryView
                selectedHistories={selectedHistories}
                setSelectedHistories={setSelectedHistories}
              />
            )}
            {isExistHistory(selectedHistories, 'investigations') && (
              <InvestigationView
                selectedHistories={selectedHistories}
                setSelectedHistories={setSelectedHistories}
                isHistoryPage={isHistoryPage}
              />
            )}
            {isExistHistory(selectedHistories, 'personal') && (
              <PersonalView
                selectedHistories={selectedHistories}
                setSelectedHistories={setSelectedHistories}
                isHistoryPage={isHistoryPage}
                removeItemsData={removeItemsData}
              />
            )}
            {selectedHistories.family && (
              <FamilyView
                selectedHistories={selectedHistories}
                setSelectedHistories={setSelectedHistories}
                isHistoryPage={isHistoryPage}
                removeItemsData={removeItemsData}
              />
            )}
            {isExistHistory(selectedHistories, 'gynecology') && (
              <GynecologyView
                selectedHistories={selectedHistories}
                setSelectedHistories={setSelectedHistories}
                prescriptionItems={prescriptionItems}
              />
            )}
            {isExistHistory(selectedHistories, 'surgical') && (
              <SurgicalView
                selectedHistories={selectedHistories}
                setSelectedHistories={setSelectedHistories}
                prescriptionItems={prescriptionItems}
              />
            )}
            {isExistHistory(selectedHistories, 'others') && (
              <OthersView
                selectedHistories={selectedHistories}
                setSelectedHistories={setSelectedHistories}
                prescriptionItems={prescriptionItems}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(HistoryView);
