import React, { useState, useRef, Fragment, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { getRequest, postRequest } from '../../../../utils/axiosRequests';
import InvestigationUnit from '../../partials/investigationUnit';
import { useGetRequest } from '../../../../hooks/useGetRequest';

const InvestigationHistories = ({ selectedData, setSelectedHistories }) => {
  const [investigationList, setInvestigationList] = useState([]);
  const [units, setUnits] = useState([]);
  const invRef = useRef(null);

  const { isLoading: isUnitLoading, refetch: getUnits } = useGetRequest(
    'getUnitList',
    `units`,
    (data) => {
      setUnits(data.data);
    },
    (e) => {
      console.log(e);
    },
  );

  useEffect(() => {
    getUnits();
  }, []);

  const mergeSelectedInv = (newData) => {
    const selectedInvestigate = [
      ...selectedData.investigations,
      { name: newData, result: '', instruction: '' },
    ];
    selectedData.investigations = selectedInvestigate;
    setSelectedHistories({ ...selectedData });
  };

  const handleInvestigationUnit = (data) => {
    // selectedData.investigations[index][fieldName] = val;
    // setSelectedHistories({ ...selectedData });
  };

  const deleteData = (itemName) => {
    const updatedInvestigations = selectedData?.investigations?.filter(
      (investigation) => investigation.name !== itemName,
    );
    setSelectedHistories({
      ...selectedData,
      investigations: updatedInvestigations,
    });
  };

  const selectedInvestigations = () => {
    return selectedData?.investigations?.map((item, index) => {
      return (
        <Row className={'mt-1'} key={index}>
          <Col md={3} className="pl-2">
            {item.name}
          </Col>
          <Col className="pl-0">
            <Form.Control
              size="sm"
              min={0}
              type="text"
              defaultValue={item.instruction}
              placeholder="Add Instruction"
              onChange={(e) =>
                investigationData(index, 'instruction', e.target.value)
              }
            />
          </Col>
          <Col className="pl-0">
            <Form.Control
              rows={3}
              size="sm"
              placeholder="Add Result"
              defaultValue={item?.result}
              onChange={(e) =>
                investigationData(index, 'result', e.target.value)
              }
            />
          </Col>
          <Col md={3} className="inv-parent pr-0 pl-0">
            <InvestigationUnit
              index={index}
              units={units}
              item={item}
              selectedInvestigations={selectedData.investigations}
              action={handleInvestigationUnit}
              type={'history'}
            />
          </Col>
          <Col md={1} className="text-center">
            <span
              className="cursor-pointer"
              onClick={() => deleteData(item?.name)}
            >
              <i
                className="fa fa-times-circle pt-2"
                style={{ color: '#CB2020D9' }}
              ></i>
            </span>
          </Col>
        </Row>
      );
    });
  };

  const investigationData = (index, fieldName, val) => {
    selectedData.investigations[index][fieldName] = val;
    setSelectedHistories({ ...selectedData });
  };

  const handleInvestigation = async (optionVal) => {
    const selectedVal = optionVal[0]?.name;
    if (investigationList?.some((item) => item?.name === selectedVal)) {
      if (
        !selectedData?.investigations?.some((item) => item.name === selectedVal)
      ) {
        mergeSelectedInv(selectedVal);
        setInvestigationList([]);
        handleOnInputChange('');
        invRef?.current?.clear();
      }
    } else {
      await postRequest('investigations', { name: selectedVal })
        .then((data) => {
          mergeSelectedInv(selectedVal);
          setInvestigationList([]);
          invRef?.current?.clear();
        })
        .catch((error) => {
          invRef?.current?.clear();
          console.log(error);
        });
    }
  };

  const handleOnInputChange = async (searchQuery) => {
    await getRequest(
      `investigations/all-without-pagination?searchQuery=${searchQuery}`,
    )
      .then((res) => {
        setInvestigationList(res?.data || []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleOnInputChange('');
  }, []);

  return (
    <div style={{ padding: '10px' }}>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2" className="font-weight-bold pl-2">
          Investigations
        </Form.Label>
        <Col sm="9" className="mt-1">
          <Typeahead
            allowNew
            labelKey="name"
            ref={invRef}
            id="custom-selections-example"
            newSelectionPrefix="Click to add new: "
            options={investigationList}
            placeholder="Search / Add investigaton."
            onChange={handleInvestigation}
            onInputChange={handleOnInputChange}
            size="sm"
            renderMenuItemChildren={(option) => (
              <Fragment>
                <span>{option.name}</span>
              </Fragment>
            )}
          />
        </Col>
      </Form.Group>
      <div className="mt-3">{selectedInvestigations()}</div>
    </div>
  );
};

export default InvestigationHistories;
