import { memo } from 'react';
import { styledComponent } from '../../../../../utils/helpers';
import CommonDeleteBtn from '../../commonDeleteBtn';

const MedicalView = ({
  selectedHistories,
  setSelectedHistories,
  prescriptionItems,
  removeItemsData,
  isHistoryPage,
}) => {

  const Title = styledComponent(
    prescriptionItems?.items?.['history']?.itemStyle || {},
  );
  const Value = styledComponent(
    prescriptionItems?.items?.['history']?.subItemStyle || {},
  );

  const medicalHistoriesExtra = (data) => {
    return (
      <>
        {data?.duration ? ' - for ' + data?.duration : ''}{' '}
        {data?.duration ? data?.unit : ''}
      </>
    );
  };

  return (
    <>
      <div className="his-sub-title">M/H</div>
      <ul key={`medical`}>
        {selectedHistories?.medical?.map((item, index) => {
          return (
            <li>
              <div key={index}>
                <span className="text-left pr-5">
                  <Title>{item.name}</Title>
                  {item?.present === 'yes' && (
                    <span>
                      <Value>: Yes {medicalHistoriesExtra(item)}</Value>
                    </span>
                  )}
                  {item?.absent === 'no' && (
                    <span>
                      <Value>: No {medicalHistoriesExtra(item)}</Value>
                    </span>
                  )}
                </span>
                {!isHistoryPage && (
                  <CommonDeleteBtn
                    action={removeItemsData}
                    itemName={'medical'}
                    delId={item.name}
                  />
                )}
              </div>
              {item.note !== '' && (
                <div className="pl-2">
                  <Value>- {item.note}</Value>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default memo(MedicalView);
