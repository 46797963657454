import React, { memo, useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row, Tab } from 'react-bootstrap';
import { useClickAway } from 'react-use';
import { useDebouncedValue } from '../../../hooks/useDebouncedValue';
import { useGetRequest } from '../../../hooks/useGetRequest';
import {
  deleteRequest,
  getRequest,
  postRequest,
} from '../../../utils/axiosRequests';
import { handleGroupData } from '../../../utils/helpers';
import ChiefComplainGroupForm from '../modals/chiefComplainGroupForm';
import GroupWithDeleteIcon from '../partials/groupWithDeleteIcon';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import LoadMore from '../partials/loadMore';
import ModalHeader from '../partials/modalHeader';
import SearchArea from '../partials/searchArea';
import TabNav from '../partials/tabNav';
import SearchItemSuggestions from '../searchItemSuggestions';

const ChiefComplainModal = ({
  modalTitle,
  selectedChiefComplains,
  setSelectedChiefComplains,
  selectedComplainGroups,
  setSelectedComplainGroups,
  isComplainModal,
  setIsComplainModal,
}) => {
  const [chiefComplains, setChiefComplains] = useState([]);
  const [chiefComplainsInSearch, setChiefComplainsInSearch] = useState([]);
  const [complainGroups, setComplainGroups] = useState([]);
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [searchItemName, setSearchItemName] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const perPage = 25;
  const [selectedIndexForFocus, setSelectedIndexForFocus] = useState(null);

  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    setSelectedIndexForFocus(index);
  };

  //============for search suggestions ======================//
  const [clickedItemForShowSuggestion, setClickedItemForShowSuggestion] =
    useState('');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);
  const [suggetionMenuTop, setSuggetionMenuTop] = useState(4);

  const getChipComplainGroups = async () => {
    try {
      const data = await getRequest('chief-complains/groups');
      console.log(data, 'data');
      setComplainGroups(data);
    } catch (error) {
      console.error(error);
    }
  };

  const openDropdown = () => {
    // setIsSubItemSuggestionOpen(true);
    setIsSubItemSuggestionOpen(true);
  };

  const closeDropdown = () => {
    setIsSubItemSuggestionOpen(false);
  };

  // const handleSelectSearchSuggestion = (result, itemName) => {
  //   let data = [...selectedChiefComplains];
  //   const item = chiefComplains?.find(
  //     (chiefItem) => chiefItem.name === itemName,
  //   );
  //   //item['note'] = result;
  //   const isItemExist = data.some((complain) => {
  //     if (complain.name === item.name) {
  //       return true;
  //     }
  //   });

  //   let updatedComplains = [];
  //   if (isItemExist) {
  //     updatedComplains = data.map((complain) => {
  //       if (complain.name !== item.name) return complain;
  //       else return { ...complain, note: item?.note };
  //     });
  //   } else {
  //     updatedComplains = [
  //       ...data,
  //       {
  //         name: item.name,
  //         duration: '',
  //         unit: 'day(s)',
  //         note: item?.note ? item.note : '',
  //       },
  //     ];
  //   }
  //   setSelectedChiefComplains([...updatedComplains]);
  // };

  const handleSelectSearchSuggestion = (note, itemName) => {
    const item = chiefComplains?.find(
      (chiefItem) =>
        chiefItem.name?.trim()?.toLowerCase() === itemName.trim().toLowerCase(),
    );

    if (
      selectedChiefComplains?.some(
        (data) => data.name.trim() === item.name.trim(),
      )
    ) {
      setSelectedChiefComplains(
        selectedChiefComplains.map((complain) => {
          if (complain.name !== item.name) return complain;
          else return { ...complain, note: item?.note };
        }),
      );
    } else {
      console.log(item, note, 'item');
      setSelectedChiefComplains([
        ...selectedChiefComplains,
        { name: item.name, duration: '', unit: 'day(s)', note: note },
      ]);
    }
  };

  const handleClickOnRightArrow = (e, itemName) => {
    e.stopPropagation();
    // console.log('x, y : ', e.pageX, e.pageY);
    if (clickedItemForShowSuggestion === itemName) {
      setClickedItemForShowSuggestion('');
      closeDropdown();
    } else {
      setClickedItemForShowSuggestion(itemName);
      const topPosition = Math.abs(e.pageY - 160) / 16 + 5;
      setSuggetionMenuTop(topPosition);
      openDropdown();
    }
  };
  //============End search suggestions ======================//

  const { isLoading: isChipComplainLoading, refetch: getChipComplains } =
    useGetRequest(
      'getChipComplain',
      `chief-complains?page=${currentPage}&perPage=${perPage}`,
      (data) => {
        if (currentPage > 1) {
          setChiefComplains([...chiefComplains, ...data.data]);
        } else {
          setChiefComplains(data.data);
          setTotalItem(data.total);
        }
      },
      (e) => {
        console.log(e);
      },
    );

  const selectChiefComplain = (item) => {
    const isItemExist = selectedChiefComplains?.some(
      (complain) => complain.name === item.name,
    );
    const updatedComplains = isItemExist
      ? selectedChiefComplains?.filter(
          (complain) => complain.name !== item.name,
        )
      : [
          ...selectedChiefComplains,
          { name: item.name, side: '', duration: '', unit: 'day(s)', note: '' },
        ];
    setSelectedChiefComplains(updatedComplains);
  };

  const deleteChiefComplain = async (complainId) => {
    await deleteRequest(`chief-complains/${complainId}`)
      .then((data) => {
        setChiefComplains(
          chiefComplains?.filter((complain) => complain.id !== complainId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteChiefComplainGroup = async (groupId) => {
    await deleteRequest(`chief-complains/groups/${groupId}`)
      .then((data) => {
        setComplainGroups(
          complainGroups.filter((group) => group.id !== groupId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectComplainGroup = (group) => {
    handleGroupData(
      group,
      selectedComplainGroups,
      setSelectedComplainGroups,
      selectedChiefComplains,
      setSelectedChiefComplains,
      'Chief complain group',
    );
  };

  const removeChiefComplain = (item) => {
    setSelectedChiefComplains(
      selectedChiefComplains?.filter(
        (selectedItem) => selectedItem.name !== item.name,
      ),
    );
  };

  const handleChiefComplainExtras = (item, fieldName, val) => {
    const objIndex = selectedChiefComplains.findIndex(
      (complain) => complain.name == item.name,
    );
    selectedChiefComplains[objIndex][fieldName] = val;
    setSelectedChiefComplains([...selectedChiefComplains]);
  };

  const { isLoading: isSuggestionLoading, refetch: getSuggestions } =
    useGetRequest(
      'getChipComplainSuggestions',
      `prescriptions/suggestions?suggestion_type=chief_complain`,
      (data) => {
        console.log('sug1: ', data.suggestions);
        setSuggestions(data.suggestions);
      },
      (e) => {
        console.log(e);
      },
    );

  const selectChiefComplainWithNote = (item) => {
    let data = [...selectedChiefComplains];
    const isItemExist = data.some((complain) => {
      if (complain.name === item.name) {
        return true;
      }
    });

    let updatedComplains = [];
    if (isItemExist) {
      updatedComplains = data.map((complain) => {
        if (complain.name !== item.name) return complain;
        else return { ...complain, note: item?.note };
      });
    } else {
      updatedComplains = [
        ...data,
        {
          name: item.name,
          duration: '',
          unit: 'day(s)',
          note: item?.note ? item.note : '',
        },
      ];
    }
    setSelectedChiefComplains([...updatedComplains]);
  };

  useEffect(() => {
    getChipComplainGroups();
  }, []);

  useEffect(() => {
    getChipComplains();
  }, [currentPage]);

  useEffect(() => {
    if (isComplainModal) {
      getSuggestions();
    }
  }, [isComplainModal]);

  const SelectedComplain = ({
    item,
    handleChiefComplainExtras,
    removeChiefComplain,
    inputRefs,
    handleFocus,
    index,
    selectedIndexForFocus,
  }) => {
    const [note, setNote] = useState('');
    const [duration, setDuration] = useState('');
    const debouncedNote = useDebouncedValue(note, 500);
    const debouncedDuration = useDebouncedValue(duration, 500);
    const componentRef = useRef(null);

    useEffect(() => {
      if (debouncedNote) {
        handleChiefComplainExtras(item, 'note', debouncedNote);
      }
    }, [debouncedNote]);

    useEffect(() => {
      inputRefs.current[selectedIndexForFocus]?.focus();
    }, []);

    // useEffect(() => {
    //   const handleClickOutside = (event) => {
    //     if (inputRefs.current && !inputRefs.current.includes(event.target)) {
    //       // If click is outside the component, blur the input fields
    //       inputRefs.current[index]?.blur();
    //       inputRefs.current[index + 'd']?.blur();
    //     }
    //   };

    //   document.addEventListener('mousedown', handleClickOutside);

    //   return () => {
    //     document.removeEventListener('mousedown', handleClickOutside);
    //   };
    // }, [inputRefs, index]);

    useClickAway(componentRef, () => {
      handleFocus(-1);
    });

    useEffect(() => {
      if (debouncedDuration) {
        handleChiefComplainExtras(item, 'duration', debouncedDuration);
      }
    }, [debouncedDuration]);

    return (
      <Row className="selected-item-row" ref={componentRef}>
        <Col className="name" md="3" sm="12" xs="12">
          {item.name} <span className="pl-2">for</span>
        </Col>
        <Col md="6" sm="12" xs="12">
          <Row>
            <Col md="4" sm="3" xs="3" className="responsive pl-0 pr-0">
              <Form.Select
                className="form-control form-control-sm"
                defaultValue={item?.side}
                onChange={(e) =>
                  handleChiefComplainExtras(item, 'side', e.target.value)
                }
              >
                <option value="">Side</option>
                <option value={'rt'}>RT</option>
                <option value={'lt'}>LT</option>
                <option value={'bil'}>BIL</option>
              </Form.Select>
            </Col>
            <Col md="3" sm="6" xs="6" className="pr-0 responsive">
              <Form.Control
                ref={(el) => (inputRefs.current[index + 'd'] = el)}
                size="sm"
                defaultValue={item.duration}
                min={0}
                type="number"
                placeholder="duration"
                onChange={(e) => setDuration(e.target.value)}
                onClick={(e) => handleFocus(index + 'd')}
              />
            </Col>
            <Col md="5" sm="6" xs="6" className="responsive pr-0">
              <Form.Select
                className="form-control form-control-sm"
                defaultValue={item.unit}
                onChange={(e) =>
                  handleChiefComplainExtras(item, 'unit', e.target.value)
                }
              >
                <option value={'hour(s)'}>Hour(s)</option>
                <option value={'day(s)'}>Day(s)</option>
                <option value={'week(s)'}>Week(s)</option>
                <option value={'month(s)'}>Month(s)</option>
                <option value={'year(s)'}>Year(s)</option>
                <option value={'long time'}>Long Time</option>
              </Form.Select>
            </Col>
          </Row>
        </Col>
        <Col md="3" sm="12" xs="12" className="responsive">
          <Row>
            <Col md="10" xs="10" className="pr-0">
              <Form.Control
                ref={(el) => (inputRefs.current[index] = el)}
                size="sm"
                type="text"
                defaultValue={item.note}
                placeholder="Enter note"
                onChange={(e) => setNote(e.target.value)}
                onClick={(e) => handleFocus(index)}
              />
            </Col>
            <Col lg="1" md="1" sm="1" xs="2">
              <i
                className="fa fa-times-circle pt-2 cursor-pointer"
                aria-hidden="true"
                onClick={() => removeChiefComplain(item)}
              ></i>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  let allChiefComplains = chiefComplains?.map((item, index) => {
    let isSelected = selectedChiefComplains.some(
      (data) => data.name === item.name,
    );
    isSelected = isSelected ? true : false;

    return (
      <ItemWithDeleteIcon
        key={index}
        item={item}
        isSelected={isSelected}
        itemClickAction={selectChiefComplain}
        removeClickAction={deleteChiefComplain}
        suggestions={suggestions}
        selectItemWithNote={selectChiefComplainWithNote}
      />
    );
  });

  let groupWithMinusBtn = complainGroups.map((group, index) => {
    let isSelected = selectedComplainGroups.some(
      (data) => data.id === group.id,
    );

    return (
      <GroupWithDeleteIcon
        key={index}
        item={group}
        isSelected={isSelected}
        itemClickAction={selectComplainGroup}
        removeClickAction={deleteChiefComplainGroup}
      />
    );
  });

  const handleSearchOrNew = async (selectedOption) => {
    try {
      const selectedData = selectedOption;
      const isExistingComplain = selectedChiefComplains.some(
        (complain) =>
          complain?.name?.toLowerCase() === selectedData?.name?.toLowerCase(),
      );
      const filteredComplains = isExistingComplain
        ? selectedChiefComplains.filter(
            (item) =>
              item?.name?.toLowerCase() !== selectedData?.name?.toLowerCase(),
          )
        : [
            ...selectedChiefComplains,
            {
              name: selectedData.name,
              side: '',
              duration: '',
              unit: 'day(s)',
              note: '',
            },
          ];
      setSelectedChiefComplains(filteredComplains);
      if (selectedData?.id === 'notFound') {
        const data = await postRequest('chief-complains', {
          name: selectedData.name,
        });
        setChiefComplains([...chiefComplains, data]);
        setSearchItemName('');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnInputChange = (searchKey) => {
    setIsLoading(true);
    if (searchKey) {
      setSearchItemName(searchKey);
      setChiefComplainsInSearch([]);
      const url = `chief-complains?name=${encodeURIComponent(searchKey)}`;
      setSearchQuery(searchKey);

      getRequest(url)
        .then((data) => {
          const customizedResults =
            data?.data?.length > 0
              ? data.data.map((item) => ({
                  ...item,
                  label: item.name,
                  value: item.name,
                }))
              : [{ id: 'notFound', name: searchKey }];

          setChiefComplainsInSearch(customizedResults);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    const clickedArea = e.target;
    if (clickedArea.classList.contains('customize-modal-size')) {
      setIsComplainModal(false);
    } else if (clickedArea.classList.contains('child-modal')) {
      setIsGroupModal(false);
    }
  });

  const formatOptionLabel = ({ value, label }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // paddingTop: '5px',
        position: 'relative',
      }}
    >
      <span style={{ paddingTop: '4px' }}>{label}</span>
      <span>
        <i
          className="fa fa-angle-right"
          style={{
            color: '#00b38c',
            // paddingTop: '5px',
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingTop: '8px',
            paddingBottom: '5px',
            fontSize: '18px',
            // border: '1px solid lightGrey',
          }}
          onClick={(e) => handleClickOnRightArrow(e, label)}
        />
      </span>
    </div>
  );

  return (
    <Modal show={isComplainModal} size="lg" className="customize-modal-size">
      <ModalHeader title={modalTitle} action={setIsComplainModal} />
      <Modal.Body ref={ref}>
        <SearchArea
          handleOnInputChange={handleOnInputChange}
          handleSearchOrNew={handleSearchOrNew}
          searchQuery={searchQuery}
          searchItemName={searchItemName}
          options={chiefComplainsInSearch}
          selectedInputs={selectedChiefComplains}
          placeholder={'chief complain'}
          formatOptionLabel={formatOptionLabel}
        />
        {/* <TestSelect /> */}
        <Tab.Container id="left-tabs-example" defaultActiveKey="all">
          <TabNav
            action={setIsGroupModal}
            selectedItems={selectedChiefComplains}
          />
          <Tab.Content>
            <Tab.Pane eventKey="all" className="add-scroll">
              <Row className="complains-area mr-0 ml-0">
                {allChiefComplains}
              </Row>
              <LoadMore
                currentPage={currentPage}
                totalItem={totalItem}
                perPage={perPage}
                currentPageAction={setCurrentPage}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="group" className="add-scroll">
              <Row className="complains-area mr-0 ml-0 mt-1">
                {groupWithMinusBtn}
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <ChiefComplainGroupForm
          modalTitle={modalTitle}
          isGroupModal={isGroupModal}
          setIsGroupModal={setIsGroupModal}
          itemNames={selectedChiefComplains.map((item) => item.name)}
          complainGroups={complainGroups}
          setComplainGroups={setComplainGroups}
          selectedComplainGroups={selectedComplainGroups}
          setSelectedComplainGroups={setSelectedComplainGroups}
        />

        <hr className="selected-hr" />
        <div className="selected-item-title">Selected list</div>
        <div className="selected-item-area">
          {/* <SelectedComplainList
            selectedChiefComplains={selectedChiefComplains}
            handleChiefComplainExtras={handleChiefComplainExtras}
            removeChiefComplain={removeChiefComplain}
          /> */}
          {selectedChiefComplains?.map((item, index) => (
            <SelectedComplain
              key={index}
              item={item}
              index={index}
              handleChiefComplainExtras={handleChiefComplainExtras}
              removeChiefComplain={removeChiefComplain}
              inputRefs={inputRefs}
              handleFocus={handleFocus}
              selectedIndexForFocus={selectedIndexForFocus}
            />
          ))}
        </div>

        <SearchItemSuggestions
          openDropdown={openDropdown}
          isSubItemSuggestionOpen={isSubItemSuggestionOpen}
          handleSelect={handleSelectSearchSuggestion}
          closeDropdown={closeDropdown}
          suggestions={suggestions}
          itemName={clickedItemForShowSuggestion}
          suggetionMenuTop={suggetionMenuTop}
        />
      </Modal.Body>
    </Modal>
  );
};
export default memo(ChiefComplainModal);
