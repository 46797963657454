import React, { memo } from 'react';
import { itemSettingStyle } from '../../../../../utils/helpers';
import CommonDeleteBtn from '../../commonDeleteBtn';

const FamilyView = ({
  selectedHistories,
  setSelectedHistories,
  prescriptionItems,
  isHistoryPage,
  removeItemsData,
}) => {
  const deleteData = (fieldName, delId) => {
    const updatedInvestigations = selectedHistories?.investigations?.filter(
      (investigation) => investigation.name !== delId,
    );
    setSelectedHistories({
      ...selectedHistories,
      investigations: updatedInvestigations,
    });
  };

  return (
    <div key={`family`}>
      <div className="his-sub-title">F/H</div>
      <ul>
        <li className="text-left">
          <span
            style={itemSettingStyle(
              prescriptionItems?.items?.['history']?.itemStyle || {},
            )}
          >
            Family:
          </span>{' '}
          <span
            style={itemSettingStyle(
              prescriptionItems?.items?.['history']?.subItemStyle || {},
            )}
          >
            {selectedHistories.family}
          </span>
          {!isHistoryPage && (
            <CommonDeleteBtn
              action={removeItemsData}
              itemName={'family'}
              delId={'family'}
            />
          )}
        </li>
      </ul>
    </div>
  );
};

export default memo(FamilyView);
