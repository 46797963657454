import React, { memo } from 'react';
import {
  styledComponent,
  defaultGeneralData,
} from '../../../../utils/helpers';
import CommonDeleteBtn from '../commonDeleteBtn';

const GeneralExaminationView = ({
  prescriptionItems,
  selectedOnExamination,
  setSelectedOnExamination,
  isHistoryPage,
}) => {
  let { generalExaminations } = selectedOnExamination;

  const removeItemsData = (fieldName) => {
    const resetFields = defaultGeneralData();
    // Handle field reset
    if (fieldName in resetFields) {
      const resetValue = resetFields[fieldName];
      if (typeof resetValue === 'object') {
        generalExaminations[fieldName] = { ...resetValue };
      } else {
        generalExaminations[fieldName] = resetValue;
      }
    }

    // Update the state
    setSelectedOnExamination({
      ...selectedOnExamination,
      generalExaminations: { ...generalExaminations },
    });
  };

  // const removeItemsData = (fieldName) => {
  //   if (fieldName === 'bloodPressure') {
  //     generalExaminations.bloodPressure = { systolic: '', diastolic: '' };
  //   } else if (fieldName === 'pulseInfo') {
  //     generalExaminations.pulseInfo = {
  //       pulse: '',
  //       pulseUnit: 'PM',
  //       pulseType: '',
  //     };
  //   } else if (fieldName === 'temperatureInfo') {
  //     generalExaminations.temperatureInfo = {
  //       temperature: '',
  //       temperatureUnit: 'F',
  //       temperatureType: '',
  //     };
  //   } else if (fieldName === 'weightInfo') {
  //     generalExaminations.weightInfo = {
  //       weight: '',
  //       weightUnit: 'KG',
  //       weightShowInPrescription: '',
  //     };
  //   } else if (fieldName === 'bsa') {
  //     generalExaminations.showBsa = false;
  //     generalExaminations.bsa = '';
  //   } else if (fieldName === 'heightInfo') {
  //     generalExaminations.heightInfo = {
  //       feet: '',
  //       inch: '',
  //       heightUnit: 'Ft',
  //       heightShowInPrescription: '',
  //     };
  //   } else if (fieldName === 'bmi') {
  //     generalExaminations.showBmi = false;
  //     generalExaminations.bmi = '';
  //   } else if (fieldName === 'idealWeightInfo') {
  //     generalExaminations.idealWeightInfo = {
  //       idealWeight: '',
  //       idealWeightUnit: 'Ft',
  //     };
  //   } else if (fieldName === 'idealBmi') {
  //     generalExaminations.idealBmi = '';
  //   } else if (fieldName === 'respiratoryRate') {
  //     generalExaminations.respiratoryRate = '';
  //   } else if (fieldName === 'targetDailyCalory') {
  //     generalExaminations.targetDailyCalory = '';
  //   } else {
  //     generalExaminations.diabetes = '';
  //   }
  //   selectedOnExamination.generalExaminations = generalExaminations;
  //   setSelectedOnExamination({ ...selectedOnExamination });
  // };

  const Title = styledComponent(
    prescriptionItems?.items?.['on-examination']?.itemStyle || {},
  );
  const Value = styledComponent(
    prescriptionItems?.items?.['on-examination']?.subItemStyle || {},
  );

  return (
    <ul>
      {generalExaminations['bloodGroup'] && (
        <li>
          <Title>Blood Group:</Title>{' '}
          <Value>{generalExaminations['bloodGroup']}</Value>
        </li>
      )}
      {(generalExaminations['bloodPressure'].systolic ||
        generalExaminations['bloodPressure'].diastolic) && (
        <li className="text-left">
          <Title>BP:</Title>{' '}
          <Value>
            {generalExaminations['bloodPressure'].systolic}/
            {generalExaminations['bloodPressure'].diastolic} Mm Hg
          </Value>
          {!isHistoryPage && (
            <CommonDeleteBtn
              action={removeItemsData}
              itemName={'bloodPressure'}
            />
          )}
        </li>
      )}
      {generalExaminations['pulseInfo']['pulse'] && (
        <li className="text-left">
          <Title>Pulse:</Title>{' '}
          <Value>
            {generalExaminations['pulseInfo'].pulse + ' '}
            {generalExaminations['pulseInfo'].pulseUnit === 'PM'
              ? 'PM'
              : 'BPM'}{' '}
            {generalExaminations['pulseInfo']['pulseType'] && (
              <span>({generalExaminations['pulseInfo']['pulseType']})</span>
            )}
          </Value>
          {!isHistoryPage && (
            <CommonDeleteBtn action={removeItemsData} itemName={'pulseInfo'} />
          )}
        </li>
      )}
      {generalExaminations?.respiratoryRate && (
        <li className="text-left">
          <Title>Respiratory Rate (RR):</Title>{' '}
          <Value>
            <span>{generalExaminations?.respiratoryRate} </span>
          </Value>
          {!isHistoryPage && (
            <CommonDeleteBtn
              action={removeItemsData}
              itemName={'respiratoryRate'}
            />
          )}
        </li>
      )}
      {!generalExaminations['pulseInfo']['pulse'] &&
        generalExaminations['pulseInfo']['pulseType'] && (
          <li className="text-left">
            <Title>Pulse:</Title>{' '}
            <Value>
              <span>{generalExaminations['pulseInfo']['pulseType']} </span>
            </Value>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={removeItemsData}
                itemName={'pulseInfo'}
              />
            )}
          </li>
        )}
      {generalExaminations['temperatureInfo']['temperature'] && (
        <li className="text-left">
          <Title>Temperature:</Title>{' '}
          <Value>
            {generalExaminations['temperatureInfo'].temperature + ' '}
            {generalExaminations['temperatureInfo']?.temperatureUnit}{' '}
            {generalExaminations['temperatureInfo']['temperatureType'] && (
              <span>
                ({generalExaminations['temperatureInfo']['temperatureType']})
              </span>
            )}
          </Value>
          {!isHistoryPage && (
            <CommonDeleteBtn
              action={removeItemsData}
              itemName={'temperatureInfo'}
            />
          )}
        </li>
      )}
      {!generalExaminations['temperatureInfo']['temperature'] &&
        generalExaminations['temperatureInfo'].temperatureType && (
          <li className="text-left">
            <Title>Temperature:</Title>{' '}
            <Value>
              {generalExaminations['temperatureInfo'].temperatureType && (
                <span>
                  {generalExaminations['temperatureInfo'].temperatureType}
                </span>
              )}
            </Value>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={removeItemsData}
                itemName={'temperatureInfo'}
              />
            )}
          </li>
        )}
      {generalExaminations['weightInfo']['weight'] &&
        !generalExaminations['weightInfo']['hideWeight'] && (
          <li className="text-left">
            <Title>Weight:</Title>{' '}
            <Value>
              {generalExaminations['weightInfo'].weight + ' '}
              {generalExaminations['weightInfo'].weightUnit}
            </Value>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={removeItemsData}
                itemName={'weightInfo'}
              />
            )}
          </li>
        )}
      {generalExaminations.bsa && generalExaminations.showBsa && (
        <li className="text-left">
          <Title>BSA:</Title> <Value>{generalExaminations?.bsa}</Value>
          {!isHistoryPage && (
            <CommonDeleteBtn action={removeItemsData} itemName={'bsa'} />
          )}
        </li>
      )}
      {(generalExaminations['heightInfo']['feet'] ||
        generalExaminations['heightInfo']['inch']) &&
        !generalExaminations['heightInfo']['hideHeight'] && (
          <li className="text-left">
            <Title>Height:</Title>{' '}
            <Value>
              {generalExaminations['heightInfo'].feet + '.'}
              {generalExaminations['heightInfo'].inch
                ? ' ' + generalExaminations['heightInfo'].inch
                : 0}
              {' ' + generalExaminations['heightInfo'].heightUnit}
            </Value>
            {!isHistoryPage && (
              <CommonDeleteBtn
                action={removeItemsData}
                itemName={'heightInfo'}
              />
            )}
          </li>
        )}
      {generalExaminations.bmi && generalExaminations.showBmi && (
        <li className="text-left">
          <Title>BMI:</Title> <Value>{generalExaminations?.bmi}</Value>
          {!isHistoryPage && (
            <CommonDeleteBtn action={removeItemsData} itemName={'bmi'} />
          )}
        </li>
      )}
      {generalExaminations?.idealWeightInfo?.idealWeight && (
        <li className="text-left">
          <Title>Ideal Weight:</Title>{' '}
          <Value>
            {generalExaminations?.idealWeightInfo?.idealWeight + ' '}
            {generalExaminations?.idealWeightInfo?.idealWeightUnit}
          </Value>
          {!isHistoryPage && (
            <CommonDeleteBtn
              action={removeItemsData}
              itemName={'idealWeightInfo'}
            />
          )}
        </li>
      )}
      {generalExaminations['idealBmi'] && (
        <li className="text-left">
          <Title>Ideal BMI:</Title>{' '}
          <Value>{generalExaminations['idealBmi']}</Value>
          {!isHistoryPage && (
            <CommonDeleteBtn action={removeItemsData} itemName={'idealBmi'} />
          )}
        </li>
      )}
      {generalExaminations['targetDailyCalory'] && (
        <li className="text-left">
          <Title>Target Daily Calory:</Title>{' '}
          <Value>{generalExaminations['targetDailyCalory']}</Value>
          {!isHistoryPage && (
            <CommonDeleteBtn
              action={removeItemsData}
              itemName={'targetDailyCalory'}
            />
          )}
        </li>
      )}
      {generalExaminations.diabetes && (
        <li className="text-left">
          <Title>Diabetes:</Title> <Value>{generalExaminations.diabetes}</Value>
          {!isHistoryPage && (
            <CommonDeleteBtn action={removeItemsData} itemName={'diabetes'} />
          )}
        </li>
      )}
    </ul>
  );
};

export default memo(GeneralExaminationView);
