import React, { memo } from 'react';
import { itemSettingStyle } from '../../../../../utils/helpers';
import CommonDeleteBtn from '../../commonDeleteBtn';

const InvestigationView = ({
  selectedHistories,
  setSelectedHistories,
  prescriptionItems,
  isHistoryPage,
}) => {
  const deleteData = (fieldName, delId) => {
    const updatedInvestigations = selectedHistories?.investigations?.filter(
      (investigation) => investigation.name !== delId,
    );
    setSelectedHistories({
      ...selectedHistories,
      investigations: updatedInvestigations,
    });
  };

  return (
    <>
      {selectedHistories?.investigations?.length > 0 && (
        <div className="text-left">
          <div className="his-sub-title">I/H</div>
          <ul>
          {selectedHistories.investigations.map((data, index) => (
            <li key={index}>
              <span className="text-left pr-5">
                <span
                  style={itemSettingStyle(
                    prescriptionItems?.items?.history?.itemStyle || {},
                  )}
                >
                  {data.name}{' '}
                  {data?.result && (
                    <span className="pl-1">
                      -{' '}
                      <i>
                        {data.result} {data?.unit}
                      </i>
                    </span>
                  )}
                </span>
              </span>
              {!isHistoryPage && (
                <CommonDeleteBtn
                  action={deleteData}
                  itemName={'investigations'}
                  delId={data.name}
                />
              )}
              {data?.instruction && (
                <div
                  style={itemSettingStyle(
                    prescriptionItems?.items?.history?.subItemStyle || {},
                  )}
                >
                  &nbsp;&nbsp; - {data?.instruction}
                </div>
              )}
            </li>
          ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default memo(InvestigationView);
