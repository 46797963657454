import React, { useState, useEffect, memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useGetRequest } from '../../../hooks/useGetRequest';
import { getRequest, postRequest } from '../../../utils/axiosRequests';
import { Modal, Row, Col, Tab, Form } from 'react-bootstrap';
import GyneExamination from '../partials/gyneExamination';
import Observation from '../partials/observation';
import GeneralExamination from '../partials/generalExamination';
import OcularExamination from '../partials/ocularExamination';
import DentalExamination from '../partials/dentalExamination';
import SystemicExamination from '../partials/systemicExamination';
import ModalHeader from '../partials/modalHeader';
import SearchArea from '../partials/searchArea';
import OnExaminationTabNav from '../partials/onExaminationTabNav';
import OncologyExamination from '../partials/oncologyExamination';
import BreastExamination from '../partials/breastExamination';
import { useDebouncedValue } from '../../../hooks/useDebouncedValue';
import { useClickAway } from 'react-use';
import SearchItemSuggestions from '../searchItemSuggestions';

const OnExamination = ({
  modalTitle,
  patientInfo,
  setPatientInfo,
  showOnExamination,
  setShowOnExamination,
  handleOnExaminations,
  selectedOnExamination,
  setSelectedOnExamination,
  selectedOnExaminationTab,
  setSelectedOnExaminationTab,
}) => {
  let { observations, gyneExamination, breastExamination, dentalExamination } =
    selectedOnExamination;

  observations = observations?.length ? observations : [];
  const [allObservations, setAllObservations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchItemName, setSearchItemName] = useState('');
  const [observationsInSearch, setObservationsInSearch] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const perPage = 25;
  const specialties = useSelector(
    (state) => state.specialtyReducer.specialties,
  );
  const [suggestions, setSuggestions] = useState([]);

  const [selectedIndexForFocus, setSelectedIndexForFocus] = useState(null);

  const [clickedItemForShowSuggestion, setClickedItemForShowSuggestion] =
    useState('');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);
  const [suggetionMenuTop, setSuggetionMenuTop] = useState(4);

  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    setSelectedIndexForFocus(index);
  };

  const { isLoading: isObservationLoading, refetch: getObservations } =
    useGetRequest(
      'getObservation',
      `observations?page=${currentPage}&perPage=${perPage}`,
      (data) => {
        if (currentPage > 1) {
          setAllObservations([...allObservations, ...data.data]);
        } else {
          setAllObservations(data.data);
          setTotalItem(data.total);
        }
      },
      (e) => {
        console.log(e);
      },
    );

  const { isLoading: isSuggestionLoading, refetch: getSuggestions } =
    useGetRequest(
      'getObservationSuggestions',
      `prescriptions/suggestions?suggestion_type=observation`,
      (data) => {
        console.log('sug1: ', data.suggestions);
        setSuggestions(data.suggestions);
      },
      (e) => {
        console.log(e);
      },
    );

  const handleOnExaminationData = (updatedData, type) => {
    switch (type) {
      case 'observation':
        selectedOnExamination.observations = updatedData;
        break;
      case 'gyneExamination':
        selectedOnExamination.gyneExamination = updatedData;
        break;
      case 'breastExamination':
        selectedOnExamination.breastExamination = updatedData;
        break;
      case 'ocularExamination':
        selectedOnExamination.ocularExamination = updatedData;
        break;
      case 'generalExamination':
        selectedOnExamination.generalExaminations = updatedData;
        break;
      case 'dental':
        selectedOnExamination.dentalExamination = updatedData;
        break;
      case 'systemicExamination':
        selectedOnExamination[type] = updatedData;
        break;
      case 'oncologyExamination':
        selectedOnExamination[type] = updatedData;
        break;
    }
    handleOnExaminations({ ...selectedOnExamination });
  };

  const handleOnInputChange = (searchKey) => {
    setIsLoading(true);
    const url = `observations?name=${encodeURIComponent(searchKey)}`;
    setSearchQuery(searchKey);
    if (searchKey) {
      setSearchItemName(searchKey);
    }
    getRequest(url)
      .then((data) => {
        if (data.data.length > 0) {
          const customizedResults = data.data.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.name,
            };
          });
          setObservationsInSearch(customizedResults);
        } else {
          setObservationsInSearch([{ id: 'notFound', name: searchKey }]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearchOrNew = async (selectedOption) => {
    let selectedData = selectedOption;
    if (
      !observations?.some((item) => item.name === selectedData.name) &&
      selectedOption.id !== 'notFound'
    ) {
      selectedOnExamination.observations = [
        ...observations,
        { name: selectedData.name, side: '', note: '' },
      ];
      handleOnExaminations({ ...selectedOnExamination });
    } else {
      if (!selectedData || !selectedData.name || selectedData.name.length === 0)
        return;
      await postRequest('observations', { name: selectedData?.name })
        .then((data) => {
          setSearchItemName('');
          setAllObservations([...allObservations, data]);
          selectedOnExamination.observations = [
            ...observations,
            { name: selectedData.name, side: '', note: '' },
          ];
          handleOnExaminations({ ...selectedOnExamination });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setObservationsInSearch([]);
    setSearchQuery('');
  };

  const removeObservation = (itemName) => {
    observations = observations?.filter(
      (selectedItem) => selectedItem.name !== itemName,
    );
    handleOnExaminationData(observations, 'observation');
  };

  useEffect(() => {
    getObservations();
  }, [currentPage]);

  useEffect(() => {
    if (showOnExamination) getSuggestions();
  }, [showOnExamination]);

  const handleNoteOfObservation = (index, fieldName, val) => {
    observations[index][fieldName] = val;
    handleOnExaminationData(observations, 'observation');
  };

  const SingleObservation = ({
    item,
    index,
    handleNoteOfObservation,
    removeObservation,
    inputRefs,
    handleFocus,
    selectedIndexForFocus,
  }) => {
    const [note, setNote] = useState('');
    const debouncedNote = useDebouncedValue(note, 500);
    const componentRef = useRef(null);
    useEffect(() => {
      if (debouncedNote) {
        handleNoteOfObservation(index, 'note', debouncedNote);
      }
    }, [debouncedNote]);

    useEffect(() => {
      inputRefs.current[selectedIndexForFocus]?.focus();
    }, []);

    useClickAway(componentRef, () => {
      handleFocus(-1);
    });

    return (
      <Row className="selected-item-row" key={index} ref={componentRef}>
        <Col>{item.name}</Col>
        <Col md={2}>
          <Form.Select
            className="form-control form-control-sm"
            defaultValue={item?.side}
            onChange={(e) =>
              handleNoteOfObservation(index, 'side', e.target.value)
            }
          >
            <option value="">Side</option>
            <option value={'rt'}>RT</option>
            <option value={'lt'}>LT</option>
            <option value={'bil'}>BIL</option>
          </Form.Select>
        </Col>
        <Col>
          <Form.Control
            ref={(el) => (inputRefs.current[index] = el)}
            size="sm"
            type="text"
            placeholder="Enter note"
            defaultValue={item.note}
            onChange={(e) =>
              handleNoteOfObservation(index, 'note', e.target.value)
            }
            // onChange={(e) => setNote(e.target.value)}
            onClick={(e) => handleFocus(index)}
          />
        </Col>
        <Col md="1" className="text-right">
          <i
            className="fa fa-times-circle pt-1"
            aria-hidden="true"
            onClick={() => removeObservation(item.name)}
          ></i>
        </Col>
      </Row>
    );
  };

  const selectedObs = observations?.map((item, index) => {
    return (
      <SingleObservation
        item={item}
        index={index}
        handleNoteOfObservation={handleNoteOfObservation}
        removeObservation={removeObservation}
        inputRefs={inputRefs}
        handleFocus={handleFocus}
        selectedIndexForFocus={selectedIndexForFocus}
      />
    );
  });

  //============for search suggestions ======================//
  const openDropdown = () => {
    setIsSubItemSuggestionOpen(true);
  };

  const closeDropdown = () => {
    setIsSubItemSuggestionOpen(false);
  };

  const handleSelectSearchSuggestion = (note, itemName) => {
    const item = allObservations.find(
      (observationItem) => observationItem.name === itemName,
    );
    item['note'] = note;
    if (observations?.some((observation) => observation.name === item.name)) {
      observations = observations.map((observation) =>
        observation.name !== item.name
          ? observation
          : { ...observation, note: item?.note },
      );
      handleOnExaminationData(observations, 'observation');
    } else {
      observations = [...observations, { name: item.name, note: item?.note }];
      handleOnExaminationData(observations, 'observation');
    }
  };

  const handleClickOnRightArrow = (e, itemName) => {
    e.stopPropagation();
    if (clickedItemForShowSuggestion === itemName) {
      setClickedItemForShowSuggestion('');
      closeDropdown();
    } else {
      setClickedItemForShowSuggestion(itemName);
      const topPosition = Math.abs(e.pageY - 160) / 16 + 5;
      setSuggetionMenuTop(topPosition);
      openDropdown();
    }
  };

  const formatOptionLabel = ({ value, label }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <span style={{ paddingTop: '4px' }}>{label}</span>
      <span>
        <i
          className="fa fa-angle-right"
          style={{
            color: '#00b38c',
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingTop: '8px',
            paddingBottom: '5px',
            fontSize: '18px',
            // border: '1px solid lightGrey',
          }}
          onClick={(e) => handleClickOnRightArrow(e, label)}
        />
      </span>
    </div>
  );
  //============End search suggestions ======================//
  // const handleNoteOfObservation = (index, note) => {
  //   observations[index].note = note;
  //   handleOnExaminationData(observations, 'observation');
  // };

  return (
    <>
      <Modal
        show={showOnExamination}
        size="lg"
        className="customize-modal-size onexamination-modal"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ModalHeader title={modalTitle} action={setShowOnExamination} />
        <Modal.Body>
          {selectedOnExaminationTab === 'observation' && (
            <SearchArea
              handleOnInputChange={handleOnInputChange}
              handleSearchOrNew={handleSearchOrNew}
              searchQuery={searchQuery}
              searchItemName={searchItemName}
              options={observationsInSearch}
              placeholder={'observation'}
              formatOptionLabel={formatOptionLabel}
            />
          )}
          <Tab.Container id="left-tabs-example" defaultActiveKey="observation">
            <OnExaminationTabNav
              tabKey={selectedOnExaminationTab}
              setSelectedOnExaminationTab={setSelectedOnExaminationTab}
              specialties={specialties}
            />
            <Tab.Content>
              <Tab.Pane eventKey="observation" className="add-scroll">
                <Observation
                  allObservations={allObservations}
                  observations={observations}
                  handleOnExaminationData={handleOnExaminationData}
                  handleNoteOfObservation={handleNoteOfObservation}
                  currentPage={currentPage}
                  totalItem={totalItem}
                  perPage={perPage}
                  setCurrentPage={setCurrentPage}
                  suggestions={suggestions}
                />
              </Tab.Pane>
              <Tab.Pane
                eventKey="generalExamination"
                style={{ height: '450px' }}
              >
                <GeneralExamination
                  selectedOnExamination={selectedOnExamination}
                  handleOnExaminationData={handleOnExaminationData}
                />
              </Tab.Pane>
              <Tab.Pane
                eventKey="ocularExamination"
                className="add-scroll ocular-examination pt-2"
                style={{ height: '455px' }}
              >
                <OcularExamination
                  selectedOnExamination={selectedOnExamination}
                  handleOnExaminationData={handleOnExaminationData}
                />
              </Tab.Pane>
              <Tab.Pane
                eventKey="gyneExamination"
                className="add-scroll pt-2"
                style={{ height: '452px' }}
              >
                <GyneExamination
                  gyneExamination={gyneExamination}
                  handleOnExaminationData={handleOnExaminationData}
                />
              </Tab.Pane>
              <Tab.Pane
                eventKey="breastExamination"
                className="add-scroll pt-2"
                style={{ height: '453px' }}
              >
                <BreastExamination
                  breastExamination={breastExamination}
                  handleOnExaminationData={handleOnExaminationData}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="dentalExamination">
                <DentalExamination
                  dentalExamination={dentalExamination}
                  handleOnExaminationData={handleOnExaminationData}
                />
              </Tab.Pane>
              <Tab.Pane
                eventKey="systemicExamination"
                className="systemic-examination"
              >
                <SystemicExamination
                  selectedOnExamination={selectedOnExamination}
                  setSelectedOnExamination={setSelectedOnExamination}
                />
              </Tab.Pane>
              <Tab.Pane
                eventKey="oncologyExamination"
                className="oncology-examination"
              >
                <OncologyExamination
                  patientInfo={patientInfo}
                  setPatientInfo={setPatientInfo}
                  selectedOnExamination={selectedOnExamination}
                  handleOnExaminationData={handleOnExaminationData}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          {selectedOnExaminationTab === 'observation' && (
            <>
              <hr className="selected-hr" />
              <div className="selected-item-title">Selected list</div>
              <div className="selected-item-area">{selectedObs}</div>
            </>
          )}

          <SearchItemSuggestions
            openDropdown={openDropdown}
            isSubItemSuggestionOpen={isSubItemSuggestionOpen}
            handleSelect={handleSelectSearchSuggestion}
            closeDropdown={closeDropdown}
            suggestions={suggestions}
            itemName={clickedItemForShowSuggestion}
            suggetionMenuTop={suggetionMenuTop}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default memo(OnExamination);
