import React, { useState, useEffect, memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Row, Col, Form, Tab } from 'react-bootstrap';
import DiagnosisGroupForm from './diagnosisGroupForm';
import {
  getRequest,
  postRequest,
  deleteRequest,
} from '../../../utils/axiosRequests';
import { checkDoctorDept } from '../../../utils/helpers';
import { useGetRequest } from '../../../hooks/useGetRequest';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import GroupWithDeleteIcon from '../partials/groupWithDeleteIcon';
import LoadMore from '../partials/loadMore';
import ModalHeader from '../partials/modalHeader';
import SearchArea from '../partials/searchArea';
import DiagnosisTabNav from '../partials/diagnosisTabNav';
import Staging from '../partials/staging';
import { handleGroupData } from '../../../utils/helpers';
import { useDebouncedValue } from '../../../hooks/useDebouncedValue';
import { useClickAway } from 'react-use';
import SearchItemSuggestions from '../searchItemSuggestions';

const DiagnosisModal = ({
  modalTitle,
  selectedDiagnosis,
  setSelectedDiagnosis,
  selectedDiagnosisTab,
  setSelectedDiagnosisTab,
  selectedDiagnosisGroups,
  setSelectedDiagnosisGroups,
  showDiagnosis,
  setShowDiagnosis,
}) => {
  const [diagnoses, setDiagnoses] = useState([]);
  const [diagnosesInSearch, setDiagnosesInSearch] = useState([]);
  const [diagnosisGroups, setDiagnosisGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [searchItemName, setSearchItemName] = useState('');
  const perPage = 25;
  const userInfo = useSelector((state) => state.authReducer.data);
  const specialties = useSelector(
    (state) => state.specialtyReducer.specialties,
  );
  const [suggestions, setSuggestions] = useState([]);

  const [selectedIndexForFocus, setSelectedIndexForFocus] = useState(null);
  const [clickedItemForShowSuggestion, setClickedItemForShowSuggestion] =
    useState('');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);
  const [suggetionMenuTop, setSuggetionMenuTop] = useState(4);

  const inputRefs = useRef([]);
  const handleFocus = (index) => {
    setSelectedIndexForFocus(index);
  };

  const getDiagnosisGroups = async () => {
    await getRequest('diagnoses/groups')
      .then((data) => {
        setDiagnosisGroups(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (showDiagnosis) getSuggestions();
  }, [showDiagnosis]);

  const { isLoading: isSuggestionLoading, refetch: getSuggestions } =
    useGetRequest(
      'getDiagnosisSuggestions',
      `prescriptions/suggestions?suggestion_type=diagnosis`,
      (data) => {
        console.log('sug1: ', data.suggestions);
        setSuggestions(data.suggestions);
      },
      (e) => {
        console.log(e);
      },
    );

  const { isLoading: isDiagnosisLoading, refetch: getDiagnosisList } =
    useGetRequest(
      'getDiagnosis',
      `diagnoses?page=${currentPage}&perPage=${perPage}`,
      (data) => {
        if (currentPage > 1) {
          setDiagnoses([...diagnoses, ...data.data]);
        } else {
          console.log(999);
          setDiagnoses(data.data);
          setTotalItem(data.total);
        }
      },
      (e) => {
        console.log(e);
      },
    );

  const selectDiagnosis = (item) => {
    if (selectedDiagnosis.some((diagnosis) => diagnosis.name === item.name)) {
      setSelectedDiagnosis(
        selectedDiagnosis.filter((diagnosis) => diagnosis.name !== item.name),
      );
    } else {
      setSelectedDiagnosis([
        ...selectedDiagnosis,
        { name: item.name, query: false, side: '', note: '' },
      ]);
    }
  };

  const deleteDiagnosis = async (diagnosisId) => {
    await deleteRequest(`diagnoses/${diagnosisId}`)
      .then((data) => {
        setDiagnoses(
          diagnoses.filter((diagnosis) => diagnosis.id !== diagnosisId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectDiagnosisWithNote = (item) => {
    if (selectedDiagnosis.some((diagnosis) => diagnosis.name === item.name)) {
      setSelectedDiagnosis(
        selectedDiagnosis.map((diagnosis) => {
          if (diagnosis.name !== item.name) return diagnosis;
          else return { ...diagnosis, note: item?.note };
        }),
      );
    } else {
      setSelectedDiagnosis([
        ...selectedDiagnosis,
        { name: item.name, query: false, side: '', note: item?.note },
      ]);
    }
  };

  const deleteDiagnosisGroup = async (groupId) => {
    await deleteRequest(`diagnoses/groups/${groupId}`)
      .then((data) => {
        setDiagnosisGroups(
          diagnosisGroups.filter((group) => group.id !== groupId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectDiagnosisGroup = (group) => {
    handleGroupData(
      group,
      selectedDiagnosisGroups,
      setSelectedDiagnosisGroups,
      selectedDiagnosis,
      setSelectedDiagnosis,
      'Diagnosis group',
    );
  };

  const removeDiagnosis = (item) => {
    setSelectedDiagnosis(
      selectedDiagnosis.filter(
        (selectedItem) => selectedItem.name !== item.name,
      ),
    );
  };

  const handleDiagnosesExtra = (item, fieldName, value) => {
    const objIndex = selectedDiagnosis.findIndex(
      (diagnosis) => diagnosis.name == item.name,
    );
    selectedDiagnosis[objIndex][fieldName] = value;
    setSelectedDiagnosis([...selectedDiagnosis]);
  };

  let allDiagnose = diagnoses.map((item, index) => {
    let isSelected = selectedDiagnosis.find((data) => data.name === item.name);
    isSelected = isSelected ? true : false;

    return (
      <ItemWithDeleteIcon
        key={index}
        item={item}
        isSelected={isSelected}
        itemClickAction={selectDiagnosis}
        removeClickAction={deleteDiagnosis}
        suggestions={suggestions}
        selectItemWithNote={selectDiagnosisWithNote}
      />
    );
  });

  let groupWithMinusBtn = diagnosisGroups.map((group, index) => {
    let isSelected = selectedDiagnosisGroups.find(
      (data) => data.id === group.id,
    );
    isSelected = isSelected ? true : false;

    return (
      <GroupWithDeleteIcon
        key={index}
        item={group}
        isSelected={isSelected}
        itemClickAction={selectDiagnosisGroup}
        removeClickAction={deleteDiagnosisGroup}
      />
    );
  });

  const handleSearchOrNew = async (selectedOption) => {
    try {
      const selectedData = selectedOption;
      const isExistingDiagnosis = selectedDiagnosis.some(
        (diagnosis) =>
          diagnosis?.name?.toLowerCase() === selectedData?.name?.toLowerCase(),
      );
      const filteredDiagnoses = isExistingDiagnosis
        ? selectedDiagnosis.filter(
            (item) =>
              item?.name?.toLowerCase() !== selectedData?.name?.toLowerCase(),
          )
        : [
            ...selectedDiagnosis,
            { name: selectedData.name, query: false, side: '', note: '' },
          ];
      setSelectedDiagnosis(filteredDiagnoses);
      if (selectedData?.id === 'notFound') {
        const data = await postRequest('diagnoses', {
          name: selectedData.name,
        });
        setDiagnoses([...diagnoses, data]);
        setSearchItemName('');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleOnInputChange = (searchKey) => {
  //   setIsLoading(true);
  //   const url = `diagnoses?name=${searchKey}`;
  //   setSearchQuery(searchKey);
  //   if (searchKey) {
  //     setSearchItemName(searchKey);
  //   }

  //   searchKey.length &&
  //     getRequest(url)
  //       .then((data) => {
  //         if (data?.data?.length > 0) {
  //           const customizedResults = data.data.map((item) => {
  //             return {
  //               ...item,
  //               label: item.name,
  //               value: item.name,
  //             };
  //           });
  //           setDiagnosesInSearch(customizedResults);
  //         } else {
  //           setDiagnosesInSearch([{ id: 'notFound', name: searchKey }]);
  //         }
  //         setIsLoading(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  // };
  const handleOnInputChange = (searchKey) => {
    setIsLoading(true);
    if (searchKey) {
      setSearchItemName(searchKey);
      setDiagnosesInSearch([]);

      const url = `diagnoses?name=${encodeURIComponent(searchKey)}`;
      setSearchQuery(searchKey);

      getRequest(url)
        .then((data) => {
          const customizedResults =
            data?.data?.length > 0
              ? data.data.map((item) => ({
                  ...item,
                  label: item.name,
                  value: item.name,
                }))
              : [{ id: 'notFound', name: searchKey }];

          setDiagnosesInSearch(customizedResults);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    getDiagnosisGroups();
  }, []);

  useEffect(() => {
    getDiagnosisList();
  }, [currentPage]);

  const SingleDiagnosis = ({
    handleDiagnosesExtra,
    removeDiagnosis,
    item,
    inputRefs,
    selectedIndexForFocus,
    handleFocus,
    index,
  }) => {
    const [note, setNote] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const debouncedNote = useDebouncedValue(note, 500);
    const componentRef = useRef(null);
    useEffect(() => {
      if (debouncedNote) {
        handleDiagnosesExtra(
          selectedDiagnosis[selectedIndex],
          'note',
          debouncedNote,
        );
      }
    }, [debouncedNote]);

    useEffect(() => {
      inputRefs.current[selectedIndexForFocus]?.focus();
    }, []);

    useClickAway(componentRef, () => {
      handleFocus(-1);
    });

    return (
      // <Row className="selected-item-row" key={index} ref={componentRef}>
      //   <Col lg={3} md={3} sm={3} xs={5}>
      //     {item.name}
      //   </Col>
      //   <Col lg={2} md={2} sm={2} xs={4} className="pr-0 responsive">
      //     <Form.Check
      //       type={`checkbox`}
      //       id={`query`}
      //       label={`Query`}
      //       checked={item.query}
      //       onChange={(evt) =>
      //         handleDiagnosesExtra(item, 'query', evt.target.checked)
      //       }
      //     />
      //   </Col>
      //   <Col lg={2} md={2} sm={2} xs={3} className="pl-0 pr-0">
      //     <Form.Select
      //       className="form-control form-control-sm"
      //       defaultValue={item.side}
      //       onChange={(e) => handleDiagnosesExtra(item, 'side', e.target.value)}
      //     >
      //       <option value="">Side</option>
      //       <option value={'rt'}>RT</option>
      //       <option value={'lt'}>LT</option>
      //       <option value={'bil'}>BIL</option>
      //     </Form.Select>
      //   </Col>
      //   <Col lg={4} md={4} sm={3} xs={8} className="pr-0 responsive">
      //     <Form.Control
      //       size="sm"
      //       type="text"
      //       defaultValue={item.note}
      //       placeholder="Enter note"
      //       onChange={(evt) =>
      //         handleDiagnosesExtra(item, 'note', evt.target.value)
      //       }
      //     />
      //   </Col>
      //   <Col lg={1} md={1} sm={1} xs={1} className="text-right">
      //     <i
      //       className="fa fa-times-circle cursor-pointer pt-1"
      //       aria-hidden="true"
      //       onClick={() => removeDiagnosis(item)}
      //     ></i>
      //   </Col>
      // </Row>
      <Row className="selected-item-row" key={index} ref={componentRef}>
        <Col lg={3} md={3} sm={3} xs={5}>
          {item.name}
        </Col>
        <Col lg={2} md={2} sm={2} xs={4} className="pr-0 responsive">
          <Form.Check
            type={`checkbox`}
            id={`query`}
            label={`Query`}
            checked={item.query}
            onChange={(evt) =>
              handleDiagnosesExtra(item, 'query', evt.target.checked)
            }
          />
        </Col>
        <Col lg={2} md={2} sm={2} xs={3} className="pl-0 pr-0">
          <Form.Select
            className="form-control form-control-sm"
            defaultValue={item.side}
            onChange={(e) => handleDiagnosesExtra(item, 'side', e.target.value)}
          >
            <option value="">Side</option>
            <option value={'rt'}>RT</option>
            <option value={'lt'}>LT</option>
            <option value={'bil'}>BIL</option>
          </Form.Select>
        </Col>
        <Col lg={4} md={4} sm={3} xs={8} className="pr-0 responsive">
          <Form.Control
            ref={(el) => (inputRefs.current[index] = el)}
            size="sm"
            type="text"
            defaultValue={item.note}
            placeholder="Enter note"
            onChange={(evt) => {
              //handleDiagnosesExtra(item, 'note', evt.target.value)
              setNote(evt.target.value);
              setSelectedIndex(index);
            }}
            onClick={(e) => handleFocus(index)}
          />
        </Col>
        <Col lg={1} md={1} sm={1} xs={1} className="text-right">
          <i
            className="fa fa-times-circle cursor-pointer pt-1"
            aria-hidden="true"
            onClick={() => removeDiagnosis(item)}
          ></i>
        </Col>
      </Row>
    );
  };

  const isOncologyOrHematolgy = (specialityName) => {
    const result = checkDoctorDept(
      specialityName,
      userInfo?.speciality[0],
      specialties,
    );
    return result ? 'staging' : '';
  };

  //============for search suggestions ======================//
  const openDropdown = () => {
    setIsSubItemSuggestionOpen(true);
  };

  const closeDropdown = () => {
    setIsSubItemSuggestionOpen(false);
  };

  const handleSelectSearchSuggestion = (note, itemName) => {
    const item = diagnoses?.find(
      (diagnosisItem) => diagnosisItem.name === itemName,
    );
    if (selectedDiagnosis?.some((diagnosis) => diagnosis.name === item.name)) {
      setSelectedDiagnosis(
        selectedDiagnosis.map((diagnosis) => {
          if (diagnosis.name !== item.name) return diagnosis;
          else return { ...diagnosis, note: item?.note };
        }),
      );
    } else {
      setSelectedDiagnosis([
        ...selectedDiagnosis,
        { name: item.name, query: false, note: note },
      ]);
    }
  };

  const handleClickOnRightArrow = (e, itemName) => {
    e.stopPropagation();
    console.log('x, y : ', e.pageX, e.pageY);

    if (clickedItemForShowSuggestion === itemName) {
      setClickedItemForShowSuggestion('');
      closeDropdown();
    } else {
      setClickedItemForShowSuggestion(itemName);
      const topPosition = Math.abs(e.pageY - 160) / 16 + 5;
      setSuggetionMenuTop(topPosition);
      openDropdown();
    }
  };

  const formatOptionLabel = ({ value, label }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // paddingTop: '5px',
        position: 'relative',
      }}
    >
      <span style={{ paddingTop: '4px' }}>{label}</span>
      <span>
        <i
          className="fa fa-angle-right"
          style={{
            color: '#00b38c',
            // paddingTop: '5px',
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingTop: '8px',
            paddingBottom: '5px',
            fontSize: '18px',
            // border: '1px solid lightGrey',
          }}
          onClick={(e) => handleClickOnRightArrow(e, label)}
        />
      </span>
    </div>
  );
  //============End search suggestions ======================//

  return (
    <>
      <Modal
        show={showDiagnosis}
        size="lg"
        className="customize-modal-size"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ModalHeader title={modalTitle} action={setShowDiagnosis} />
        <Modal.Body>
          <SearchArea
            handleOnInputChange={handleOnInputChange}
            handleSearchOrNew={handleSearchOrNew}
            searchItemName={searchItemName}
            searchQuery={searchQuery}
            options={diagnosesInSearch}
            selectedInputs={selectedDiagnosis}
            placeholder={'diagnosis'}
            formatOptionLabel={formatOptionLabel}
          />
          <Tab.Container id="left-tabs-example" defaultActiveKey="all">
            <DiagnosisTabNav
              action={setIsGroupModal}
              selectedItems={selectedDiagnosis}
              setSelectedDiagnosisTab={setSelectedDiagnosisTab}
              type={
                isOncologyOrHematolgy('oncology') ||
                isOncologyOrHematolgy('hematology')
              }
            />
            <Tab.Content>
              <Tab.Pane eventKey="all" className="add-scroll">
                <Row className="complains-area mr-0 ml-0">{allDiagnose}</Row>
                <LoadMore
                  currentPage={currentPage}
                  totalItem={totalItem}
                  perPage={perPage}
                  currentPageAction={setCurrentPage}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="group" className="add-scroll">
                <Row className="complains-area mr-0 ml-0 mt-1">
                  {groupWithMinusBtn}
                </Row>
              </Tab.Pane>
              {selectedDiagnosisTab === 'staging' && (
                <Tab.Pane
                  eventKey="staging"
                  style={{ height: '390px' }}
                  className="add-scroll"
                >
                  <Staging />
                </Tab.Pane>
              )}
            </Tab.Content>
          </Tab.Container>

          <DiagnosisGroupForm
            modalTitle={modalTitle}
            isGroupModal={isGroupModal}
            setIsGroupModal={setIsGroupModal}
            itemNames={selectedDiagnosis.map((diagnosis) => diagnosis.name)}
            diagnosisGroups={diagnosisGroups}
            setDiagnosisGroups={setDiagnosisGroups}
            selectedDiagnosisGroups={selectedDiagnosisGroups}
            setSelectedDiagnosisGroups={setSelectedDiagnosisGroups}
          />
          <hr className="selected-hr" />
          {selectedDiagnosisTab !== 'staging' && (
            <>
              <div className="selected-item-title">Selected list</div>
              <div className="selected-item-area">
                {selectedDiagnosis.map((item, index) => (
                  <SingleDiagnosis
                    selectedDiagnosis={selectedDiagnosis}
                    handleDiagnosesExtra={handleDiagnosesExtra}
                    removeDiagnosis={removeDiagnosis}
                    inputRefs={inputRefs}
                    selectedIndexForFocus={selectedIndexForFocus}
                    handleFocus={handleFocus}
                    item={item}
                    index={index}
                  />
                ))}
              </div>
            </>
          )}

          <SearchItemSuggestions
            openDropdown={openDropdown}
            isSubItemSuggestionOpen={isSubItemSuggestionOpen}
            handleSelect={handleSelectSearchSuggestion}
            closeDropdown={closeDropdown}
            suggestions={suggestions}
            itemName={clickedItemForShowSuggestion}
            suggetionMenuTop={suggetionMenuTop}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default memo(DiagnosisModal);
