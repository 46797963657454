import { Fragment, memo } from 'react';
import { styledComponent } from '../../../../../utils/helpers';
import CommonDeleteBtn from '../../commonDeleteBtn';
import moment from 'moment';

const Gynecology = ({
  selectedHistories,
  prescriptionItems,
  setSelectedHistories,
  isDelBtn = true,
}) => {
  let { gynecology } = selectedHistories || {};

  const clearData = (itemName) => {
    switch (itemName) {
      case 'mc':
        gynecology[itemName] = {
          regular: false,
          irregular: false,
          menopause: false,
          surgicalMenopause: false,
          note: '',
        };
        break;
      case 'mp':
      case 'mcVal':
        gynecology[itemName] = '';
        break;
      case 'lmp':
        gynecology[itemName] = '';
        gynecology['lmpNote'] = '';
        break;
      case 'edd':
        gynecology[itemName] = '';
        gynecology['eddNote'] = '';
        break;
      case 'maritalStatus':
        gynecology[itemName] = {
          married: false,
          unmarried: false,
        };
        break;
      case 'marriedFor':
        gynecology[itemName] = {
          years: '',
          months: '',
          days: '',
          hours: '',
        };
        break;
      case 'gravida':
        gynecology[itemName] = '';
        gynecology['gravidaNote'] = '';
        break;
      case 'para':
        gynecology[itemName] = '';
        gynecology['paraNote'] = '';
        break;
      case 'para':
        gynecology[itemName] = '';
        gynecology['paraNote'] = '';
        break;
      case 'ageOfLastChild':
        gynecology[itemName] = {
          years: '',
          months: '',
          days: '',
          hours: '',
          note: '',
        };
        break;
      case 'fp':
        gynecology[itemName] = '';
        gynecology['fpNote'] = '';
        break;
    }
    selectedHistories['gynecology'] = gynecology;
    setSelectedHistories({ ...selectedHistories });
  };

  const getData = (data) => {
    let result = [];
    for (let key in data) {
      if (data[key] === true) {
        result.push(key);
      }
    }
    return result;
  };

  const Title = styledComponent(
    prescriptionItems?.items?.['history']?.itemStyle || {},
  );

  const Value = styledComponent(
    prescriptionItems?.items?.['history']?.subItemStyle || {},
  );

  const convertToDate = (dateString) => {
    const date = new Date(dateString);
    return moment(date)?.format('DD/MM/YYYY');
  };

  return (
    <div className="breast-exam-view">
      <div className="his-sub-title">G/H</div>
      <ul>
        {(gynecology?.mc?.regular ||
          gynecology?.mc?.irregular ||
          gynecology?.mc?.menopause ||
          gynecology?.mc?.surgicalMenopause) && (
          <li>
            <span className="text-left pr-5">
              <Title>MC:</Title>{' '}
              {gynecology?.mc?.regular && <Value>Regular</Value>}
              {gynecology?.mc?.irregular && <Value>Irregular</Value>}
              {gynecology?.mc?.menopause && <Value>Menopause</Value>}
              {gynecology?.mc?.surgicalMenopause && (
                <Value>Surgical Menopause</Value>
              )}
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'mc'}
              isDelBtn={isDelBtn}
            />
            <Value>
              {gynecology?.mc?.note && (
                <div className="pl-4 font-italic">
                  {gynecology?.mc?.note ? `- ${gynecology?.mc?.note}` : ''}
                </div>
              )}
            </Value>
          </li>
        )}
        {gynecology?.mp && (
          <li>
            <span className="text-left pr-5">
              <Title>MP:</Title>
              <Value> {gynecology?.mp}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'mp'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gynecology?.mcVal && (
          <li>
            <span className="text-left pr-5">
              <Title>MC:</Title>
              <Value> {gynecology?.mcVal}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'mcVal'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(gynecology?.lmp || gynecology?.lmpNote) && (
          <li>
            {gynecology?.lmp && (
              <Fragment>
                <span className="text-left pr-5">
                  <Title>LMP:</Title>
                  <Value> {convertToDate(gynecology?.lmp)}</Value>
                </span>
                <CommonDeleteBtn
                  action={clearData}
                  itemName={'lmp'}
                  isDelBtn={isDelBtn}
                />
              </Fragment>
            )}
            {gynecology?.lmp && gynecology?.lmpNote && (
              <Value>
                <div className="pl-4 font-italic">
                  {`- ${gynecology?.lmpNote}`}
                </div>
              </Value>
            )}
            {!gynecology?.lmp && gynecology?.lmpNote && (
              <span className="text-left pr-5">
                <Title>LMP:</Title>
                <Value>
                  <span> {gynecology?.lmpNote}</span>
                  <CommonDeleteBtn
                    action={clearData}
                    itemName={'lmp'}
                    isDelBtn={isDelBtn}
                  />
                </Value>
              </span>
            )}
          </li>
        )}
        {(gynecology?.edd || gynecology?.eddNote) && (
          <li>
            {gynecology?.edd && (
              <Fragment>
                <span className="text-left pr-5">
                  <Title>EDD:</Title>
                  <Value> {convertToDate(gynecology?.edd)}</Value>
                </span>
                <CommonDeleteBtn
                  action={clearData}
                  itemName={'edd'}
                  isDelBtn={isDelBtn}
                />
              </Fragment>
            )}
            {gynecology?.edd && gynecology?.eddNote && (
              <Value>
                <div className="pl-4 font-italic">
                  {`- ${gynecology?.eddNote}`}
                </div>
              </Value>
            )}
            {!gynecology?.edd && gynecology?.eddNote && (
              <span className="text-left pr-5">
                <Title>EDD:</Title>
                <Value>
                  <span> {gynecology?.eddNote}</span>
                  <CommonDeleteBtn
                    action={clearData}
                    itemName={'edd'}
                    isDelBtn={isDelBtn}
                  />
                </Value>
              </span>
            )}
          </li>
        )}
        {(gynecology?.maritalStatus?.married ||
          gynecology?.maritalStatus?.unmarried) && (
          <li>
            <span className="text-left pr-5">
              <Title>Marital Status:</Title>
              {gynecology?.maritalStatus?.married && <Value> Married</Value>}
              {gynecology?.maritalStatus?.unmarried && (
                <Value> Unmarried</Value>
              )}
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'maritalStatus'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {(gynecology?.marriedFor?.years ||
          gynecology?.marriedFor?.months ||
          gynecology?.marriedFor?.days ||
          gynecology?.marriedFor?.hours) && (
          <li>
            <span className="text-left pr-5">
              <Title>Marital For:</Title>
              {gynecology?.marriedFor?.years && (
                <Value> {gynecology?.marriedFor?.years} Years </Value>
              )}
              {gynecology?.marriedFor?.months && (
                <Value> {gynecology?.marriedFor?.months} Months </Value>
              )}
              {gynecology?.marriedFor?.days && (
                <Value> {gynecology?.marriedFor?.days} Days </Value>
              )}
              {gynecology?.marriedFor?.hours && (
                <Value> {gynecology?.marriedFor?.hours} Hours </Value>
              )}
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'marriedFor'}
              isDelBtn={isDelBtn}
            />
          </li>
        )}
        {gynecology?.gravida && (
          <li>
            <span className="text-left pr-5">
              <Title>Gravida:</Title>
              <Value> {gynecology?.gravida}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'gravida'}
              isDelBtn={isDelBtn}
            />
            <Value>
              {gynecology?.gravidaNote && (
                <div className="pl-4 font-italic">
                  {gynecology?.gravidaNote
                    ? `- ${gynecology?.gravidaNote}`
                    : ''}
                </div>
              )}
            </Value>
          </li>
        )}
        {gynecology?.para && (
          <li>
            <span className="text-left pr-5">
              <Title>Para:</Title>
              <Value> {gynecology?.para}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'para'}
              isDelBtn={isDelBtn}
            />
            {gynecology?.para && (
              <div className="pl-4 font-italic">
                <Value>
                  {gynecology?.paraNote ? `- ${gynecology?.paraNote}` : ''}
                </Value>
              </div>
            )}
          </li>
        )}
        {(gynecology?.ageOfLastChild?.years ||
          gynecology?.ageOfLastChild?.months ||
          gynecology?.ageOfLastChild?.days ||
          gynecology?.ageOfLastChild?.hours) && (
          <li>
            <span className="text-left pr-5">
              <Title>Age Of Last Child:</Title>
              <Value>
                {gynecology?.ageOfLastChild?.years && (
                  <span> {gynecology?.ageOfLastChild?.years} Years </span>
                )}
                {gynecology?.ageOfLastChild?.months && (
                  <span> {gynecology?.ageOfLastChild?.months} Months </span>
                )}
                {gynecology?.ageOfLastChild?.days && (
                  <span> {gynecology?.ageOfLastChild?.days} Days </span>
                )}
                {gynecology?.ageOfLastChild?.hours && (
                  <span> {gynecology?.ageOfLastChild?.hours} Hours </span>
                )}
              </Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'ageOfLastChild'}
              isDelBtn={isDelBtn}
            />
            {gynecology?.ageOfLastChild?.note && (
              <div className="pl-4 font-italic">
                <Value>
                  {gynecology?.ageOfLastChild?.note
                    ? `- ${gynecology?.ageOfLastChild?.note}`
                    : ''}
                </Value>
              </div>
            )}
          </li>
        )}
        {gynecology?.fp && (
          <li>
            <span className="text-left pr-5">
              <Title>FP:</Title>
              <Value> {gynecology?.fp}</Value>
            </span>
            <CommonDeleteBtn
              action={clearData}
              itemName={'fp'}
              isDelBtn={isDelBtn}
            />
            {gynecology?.fpNote && (
              <div className="pl-4 font-italic">
                <Value>
                  {gynecology?.fpNote ? `- ${gynecology?.fpNote}` : ''}
                </Value>
              </div>
            )}
          </li>
        )}
      </ul>
    </div>
  );
};

export default memo(Gynecology);
